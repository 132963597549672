import { computed, reactive, onMounted, watch, } from '@nuxtjs/composition-api';
import { NON_PRESCRIPTION_CARD_ID, PROVIDE_PRESCRIPTION_LATER_CARD_ID } from '~/modules/b2b/constants.client';
import { usePrescription } from './usePrescription';
const PDPFLOW_DATA = 'PDPFLOW_DATA';
const PDPFLOW_PRESCRIPTION_VALID = 'PDPFLOW_PRESCRIPTION_VALID';
const state = reactive({
    flowData: [],
    components: [],
    isPrescriptionValid: false,
});
export const usePdpFlow = (pdpFlowContent) => {
    // const { $optimizely } = useContext();
    // const { userId } = useGuestUser();
    const { setPrescription } = usePrescription();
    const flowData = computed(() => state.flowData);
    const components = computed(() => state.components);
    const isPrescriptionValid = computed(() => state.isPrescriptionValid);
    const computedPdpFlowContent = computed(() => pdpFlowContent === null || pdpFlowContent === void 0 ? void 0 : pdpFlowContent.value);
    const refreshComponents = () => {
        // by now we use the same prescription form for both A/B test
        const providePrescriptionId = 'add-prescription-form-B';
        // a/b test for provide rx later
        // let providePrescriptionId = 'add-prescription-form';
        // const { success } = await $optimizely.onReady();
        // if (success) {
        //   const variation = $optimizely.activate('enter_prescription_later_experiment', userId);
        //   if (variation === 'variation_2') {
        //     providePrescriptionId = 'add-prescription-form-B';
        //   }
        // }
        state.components = pdpFlowContent.value[0].fields.components.map((component, index) => ({
            id: component.fields.id,
            component: component.fields.component,
            enabled: !!state.flowData.find((item) => item.id === component.fields.id) || index === 0,
            contentfulId: component.fields.id === 'add-prescription' ? providePrescriptionId : component.fields.id,
        }));
    };
    onMounted(() => {
        const flowDataSession = sessionStorage.getItem(PDPFLOW_DATA);
        if (flowDataSession)
            state.flowData = JSON.parse(flowDataSession);
        const prescriptionValid = sessionStorage.getItem(PDPFLOW_PRESCRIPTION_VALID);
        if (prescriptionValid) {
            state.isPrescriptionValid = prescriptionValid === 'true';
        }
        // pdpFlowContent is queried from contentful on server side, it already have a value from client side
        if (pdpFlowContent && pdpFlowContent.value && pdpFlowContent.value.length > 0) {
            refreshComponents();
        }
    });
    watch(computedPdpFlowContent, () => {
        if (pdpFlowContent && pdpFlowContent.value && pdpFlowContent.value.length > 0) {
            refreshComponents();
        }
    });
    const enableNextComponent = (id) => {
        const index = state.components.findIndex((component) => component.id === id);
        if (index !== -1 && index + 1 < state.components.length) {
            state.components[index + 1].enabled = true;
        }
    };
    const setValidPrescription = (valid) => {
        state.isPrescriptionValid = valid;
        sessionStorage.setItem(PDPFLOW_PRESCRIPTION_VALID, valid.toString());
    };
    const setGroupData = ({ groupId, data, price, nextEvent, }) => {
        const isAddPrescriptionLater = (data === null || data === void 0 ? void 0 : data.select) === PROVIDE_PRESCRIPTION_LATER_CARD_ID;
        const isNonPrescription = (data === null || data === void 0 ? void 0 : data.select) === NON_PRESCRIPTION_CARD_ID;
        const index = state.flowData.findIndex((flow) => flow.id === groupId);
        if (index === -1) {
            state.flowData.push({ id: groupId, data, price });
        }
        else {
            state.flowData[index] = { id: groupId, data, price };
            state.flowData = [...state.flowData];
        }
        if (nextEvent) {
            const nextEventIndex = state.components.findIndex((component) => component.id === nextEvent);
            if (nextEventIndex !== -1) {
                state.components[nextEventIndex] = { ...state.components[nextEventIndex], enabled: true };
                state.components = [...state.components];
            }
        }
        else if (!isAddPrescriptionLater) {
            /* Don't enable next component if it is enter prescription later */
            enableNextComponent(groupId);
        }
        /* Set Valid Prescription if selected card is enter later */
        if (isAddPrescriptionLater || isNonPrescription) {
            const manualComponentIndex = state.components.findIndex((component) => component.id === 'prescription-manual');
            /* disable manual prescription */
            if (manualComponentIndex > -1 && state.components[manualComponentIndex].enabled) {
                state.components[manualComponentIndex].enabled = false;
            }
        }
        if (isNonPrescription) {
            const addPrescriptionComponentIndex = state.components.findIndex((component) => component.id === 'add-prescription');
            /* disable add prescription */
            if (addPrescriptionComponentIndex > -1 && state.components[addPrescriptionComponentIndex].enabled) {
                state.components[addPrescriptionComponentIndex].enabled = false;
            }
        }
        if (groupId === 'prescription-type-select') {
            state.flowData = state.flowData.filter((flow) => flow.id === 'prescription-type-select');
            setValidPrescription(false);
            setPrescription(null);
            if ((data === null || data === void 0 ? void 0 : data.select) === 'vr-lens-prescription') {
                state.components = state.components.map((component) => {
                    if (component.id === 'select-lens-coating') {
                        return { ...component, enabled: false };
                    }
                    return component;
                });
            }
        }
        if (groupId === 'select-lens-coating' && (data === null || data === void 0 ? void 0 : data.select)) {
            setValidPrescription(true);
        }
        sessionStorage.setItem(PDPFLOW_DATA, JSON.stringify(state.flowData));
    };
    const clearFlowData = ({ withoutRefresh = false } = {}) => {
        state.flowData.splice(0);
        state.prescriptionType = null;
        state.isPrescriptionValid = false;
        sessionStorage.removeItem(PDPFLOW_DATA);
        sessionStorage.removeItem(PDPFLOW_PRESCRIPTION_VALID);
        if (!withoutRefresh) {
            refreshComponents();
        }
    };
    return {
        flowData,
        components,
        isPrescriptionValid,
        setGroupData,
        clearFlowData,
        setValidPrescription,
    };
};
