import { ref, computed, defineComponent, toRef, useContext, useRoute, watch, } from '@nuxtjs/composition-api';
import { getName as getProductName, getPrice as getProductPrice, } from '~/modules/catalog/product/getters/productGetters';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import { useUser } from '~/modules/customer/composables/useUser';
import { SfLoader } from '@storefront-ui/vue';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useProductGallery } from '~/modules/catalog/product/composables/useProductGallery';
import { ZnButton, ZnCarousel, ZnModal, ZnCurrency, ZnTag, } from '~/modules/b2b/components';
import { useB2BUiState } from '~/modules/b2b/composables';
import { PdpPrescriptionFlow } from '~/modules/b2b/prescription/components';
import { PdpRating } from '~/modules/b2b/components/beast';
import { usePdpFlow, usePrescription, emptyPrescription } from '~/modules/b2b/prescription/composables';
import ProductSkeleton from '~/modules/catalog/product/components/ProductSkeleton.vue';
import { useCountrySearch, useUiState, } from '~/composables';
import { PENDING_PRESCRIPTION_BUNDLE_OPTION_LABEL, IS_PRESCRIPTION_LENS_BUNDLE_OPTION_LABEL, ITEM_ID_BUNDLE_OPTION_LABEL, PROVIDE_PRESCRIPTION_LATER_CARD_ID, NON_PRESCRIPTION_CARD_ID, ENTER_PRESCRIPTION_MANUALLY_CARD_ID, BUNDLE_OPTIONS_RULES, PRESCRIPTION_GOOSE_CARD_ID, PRESCRIPTION_CARD_ID, NON_PRESCRIPTION_GOOSE_CARD_ID, PRE_ORDER_DATES, QUEST_3_BUNDLE_SKU, QUEST_GOOSE_BUNDLE_SKU, } from '~/modules/b2b/constants.client';
import { useUrlLanguageParser } from '~/modules/b2b/localization';
import { nanoid } from 'nanoid';
export default defineComponent({
    name: 'BundleProduct',
    components: {
        ProductSkeleton,
        PdpPrescriptionFlow,
        ZnButton,
        ZnCarousel,
        ZnModal,
        SfLoader,
        ZnCurrency,
        PdpRating,
        ZnTag,
    },
    transition: 'fade',
    props: {
        product: {
            type: [Object, null],
            default: null,
        },
        isFetching: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['loading'],
    setup(props) {
        const qty = ref(1);
        const loading = ref(true);
        const product = toRef(props, 'product');
        const { $cookies } = useContext();
        const { search: searchCountry } = useCountrySearch(); // used to cached regions if they aren't yet
        const { openPreOrderBanner, showPreOrderBanner } = useUiState();
        const pageTitle = computed(() => { var _a; return ((_a = product.value) === null || _a === void 0 ? void 0 : _a.name) || 'Prescription VR Lenses '; });
        const { addItem, error: cartError, canAddToCart, removeItem, cart, cartType, } = useCart();
        const { productGallery, imageSizes } = useProductGallery(product, '', 5);
        const { isAuthenticated } = useUser();
        const { addOrRemoveItem, isInWishlist } = useWishlist();
        const { goTo } = useB2BUiState();
        const { store, storeCode } = useUrlLanguageParser();
        const checkIsPreorder = () => {
            var _a;
            if (!((_a = product.value) === null || _a === void 0 ? void 0 : _a.sku))
                return false;
            const preOrderDate = PRE_ORDER_DATES[product.value.sku](storeCode);
            if (!preOrderDate)
                return false;
            const endDate = new Date(preOrderDate);
            const comparison = new Date() < endDate;
            if (comparison) {
                openPreOrderBanner();
            }
            return comparison;
        };
        const editMode = ref(false);
        const editItem = ref(null);
        const modal = ref(null);
        const computedLoading = computed(() => loading.value || props.isFetching);
        const addToCartLoading = ref(false);
        const selectedOptions = ref(new Map());
        const customizedOptions = ref(new Map());
        const localPendingPrescription = ref(false);
        const productShortDescription = computed(() => { var _a, _b; return ((_b = (_a = props.product) === null || _a === void 0 ? void 0 : _a.short_description) === null || _b === void 0 ? void 0 : _b.html) || ''; });
        const productDescription = computed(() => { var _a, _b; return ((_b = (_a = props.product) === null || _a === void 0 ? void 0 : _a.description) === null || _b === void 0 ? void 0 : _b.html) || ''; });
        const order = computed(() => {
            var _a, _b, _c;
            return ({
                subTotal: ((_c = (_b = (_a = cart.value) === null || _a === void 0 ? void 0 : _a.prices) === null || _b === void 0 ? void 0 : _b.subtotal_excluding_tax) === null || _c === void 0 ? void 0 : _c.value) || 0,
            });
        });
        const productSpecialPrice = computed(() => getProductPrice(props.product).special);
        const addToCartError = computed(() => { var _a, _b; return (_b = (_a = cartError.value) === null || _a === void 0 ? void 0 : _a.addItem) === null || _b === void 0 ? void 0 : _b.message; });
        const { flowData, clearFlowData, isPrescriptionValid, setGroupData, setValidPrescription, } = usePdpFlow();
        const { prescription, setPrescription, formatPrescriptionForMagento, getPrescriptionFromMagento, clearImage, } = usePrescription();
        const modalData = ref({
            title: '',
            description: '',
        });
        const productPrice = computed(() => {
            var _a, _b, _c;
            let price;
            const productItems = (_a = props.product) === null || _a === void 0 ? void 0 : _a.items;
            if ((productItems === null || productItems === void 0 ? void 0 : productItems.length) > 0) {
                const flowDataPrescriptionType = (_b = flowData.value) === null || _b === void 0 ? void 0 : _b.find((data) => data.id === 'prescription-type-select');
                const selectedSKU = (_c = flowDataPrescriptionType === null || flowDataPrescriptionType === void 0 ? void 0 : flowDataPrescriptionType.data) === null || _c === void 0 ? void 0 : _c.select;
                const selectedProduct = productItems[0].options.find((option) => option.product.sku === selectedSKU);
                const mainProductPrice = selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct.product.price_range.maximum_price.final_price.value;
                price = mainProductPrice;
            }
            else {
                price = getProductPrice(props.product).regular.toFixed(2);
            }
            return price;
        });
        const findBundleOptionBySku = (sku) => {
            // @ts-ignore
            const dropdownItem = props.product.items.find((item) => item.type === 'select');
            if (!dropdownItem) {
                return null;
            }
            const checkboxItem = props.product.items.find((item) => item.type === 'checkbox');
            if (!checkboxItem) {
                return null;
            }
            const dropdownBundleOption = dropdownItem.options.find((option) => option.product.sku === sku);
            if (dropdownBundleOption) {
                return dropdownBundleOption.uid;
            }
            const checkboxBundleOption = checkboxItem.options.find((option) => option.product.sku === sku);
            if (checkboxBundleOption) {
                return checkboxBundleOption.uid;
            }
            return null;
        };
        const bundleOptions = computed(() => {
            const options = [];
            selectedOptions.value.forEach((sku) => {
                const option = findBundleOptionBySku(sku);
                if (option) {
                    options.push({ uid: option, value: 1 });
                }
            });
            return options;
        });
        const addToCartItem = async () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            // cartType could be null --> it means the cart is empty
            // cartType could be vr-lens-bundle --> it means the cart has at least one beast item // prevent to add a goose item
            // cartType could be vr-lens-bundle-goose --> it means the cart has at least one goose item // prevent to add a beast item
            // cartType could be mixed --> it means the cart has at least one beast and one goose item (not allowed)
            if ((cartType.value === QUEST_3_BUNDLE_SKU && ((_a = props.product) === null || _a === void 0 ? void 0 : _a.sku) === QUEST_GOOSE_BUNDLE_SKU)
                || (cartType.value === QUEST_GOOSE_BUNDLE_SKU && ((_b = props.product) === null || _b === void 0 ? void 0 : _b.sku) === QUEST_3_BUNDLE_SKU)) {
                modalData.value.title = 'Error';
                modalData.value.description = 'You can only add same type of lenses to your cart. Go to your cart to complete your order.';
                modal.value.open();
                return;
            }
            const selectedPrescriptionType = selectedOptions.value.get('prescription-type-select');
            const selectedLensCoating = selectedOptions.value.get('select-lens-coating');
            const updatedLens = (_c = BUNDLE_OPTIONS_RULES[selectedPrescriptionType]) === null || _c === void 0 ? void 0 : _c[selectedLensCoating].lens;
            const updatedFrame = (_d = BUNDLE_OPTIONS_RULES[selectedPrescriptionType]) === null || _d === void 0 ? void 0 : _d[selectedLensCoating].frame(prescription.value);
            selectedOptions.value.set('prescription-type-select', updatedLens);
            selectedOptions.value.set('frame', updatedFrame);
            // verify if prescription is validated
            if (!isPrescriptionValid.value) {
                modalData.value.title = 'Prescription not validated';
                modalData.value.description = 'Please validate your prescription before adding to cart';
                modal.value.open();
                return;
            }
            if (flowData.value) {
                addToCartLoading.value = true;
                // edit or new item mode?
                if (editMode.value) {
                    console.log('UPDATE CART ITEM');
                    // first remove previous item as it is not possible to update a bundle item
                    await removeItem({ product: editItem.value });
                    console.log('UPDATED CART ITEM FINISHED');
                }
                else if (!store.multiItemCart && ((_e = cart.value.items) === null || _e === void 0 ? void 0 : _e.length) > 0) {
                    modalData.value.title = 'Lenses already added';
                    modalData.value.description = 'You can only add one pair of lenses per order in your region. Go to your cart to complete your order.';
                    modal.value.open();
                    addToCartLoading.value = false;
                    return;
                }
                const pendingPrescription = (_g = (_f = props.product) === null || _f === void 0 ? void 0 : _f.options) === null || _g === void 0 ? void 0 : _g.find((option) => option.title === PENDING_PRESCRIPTION_BUNDLE_OPTION_LABEL);
                customizedOptions.value.set(pendingPrescription.uid, { uid: pendingPrescription.uid, value: localPendingPrescription.value });
                const itemId = (_j = (_h = props.product) === null || _h === void 0 ? void 0 : _h.options) === null || _j === void 0 ? void 0 : _j.find((option) => option.title === ITEM_ID_BUNDLE_OPTION_LABEL);
                customizedOptions.value.set(itemId.uid, { uid: itemId.uid, value: nanoid() });
                const items = {
                    product: {
                        ...props.product,
                        bundle_options: [
                            ...bundleOptions.value,
                            ...customizedOptions.value.values(),
                        ],
                    },
                    quantity: 1,
                };
                addItem(items).then(() => {
                    goTo({ path: '/cart', query: { editItem: undefined } });
                    clearFlowData({ withoutRefresh: true });
                    clearImage();
                    setPrescription(emptyPrescription);
                    return true;
                }).catch((err) => {
                    console.log(err);
                    addToCartLoading.value = false;
                });
            }
        };
        const handleSelection = (selection) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            switch (selection.id) {
                case 'select-lens-coating':
                    selectedOptions.value.delete('select-lens-coating');
                    selectedOptions.value.set('select-lens-coating', selection.select);
                    break;
                case 'prescription-type-select':
                    customizedOptions.value.clear();
                    selectedOptions.value.clear();
                    const isPrescriptionLens = (_b = (_a = props.product) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.find((option) => option.title === IS_PRESCRIPTION_LENS_BUNDLE_OPTION_LABEL);
                    const isPlano = selection.select.includes(NON_PRESCRIPTION_CARD_ID);
                    customizedOptions.value.set(isPrescriptionLens.uid, { uid: isPrescriptionLens.uid, value: !isPlano });
                    if (isPlano) {
                        const pendingPrescriptionOption = (_d = (_c = props.product) === null || _c === void 0 ? void 0 : _c.options) === null || _d === void 0 ? void 0 : _d.find((option) => option.title === PENDING_PRESCRIPTION_BUNDLE_OPTION_LABEL);
                        customizedOptions.value.set(pendingPrescriptionOption.uid, { uid: pendingPrescriptionOption.uid, value: false });
                    }
                    selectedOptions.value.set('prescription-type-select', selection.select);
                    break;
                case 'add-prescription':
                    const selectedCard = selection.select === PROVIDE_PRESCRIPTION_LATER_CARD_ID ? PENDING_PRESCRIPTION_BUNDLE_OPTION_LABEL : selection.select;
                    const isCustomField = (_f = (_e = props.product) === null || _e === void 0 ? void 0 : _e.options) === null || _f === void 0 ? void 0 : _f.find((option) => option.title === selectedCard);
                    if (isCustomField) {
                        if (isCustomField.title === PENDING_PRESCRIPTION_BUNDLE_OPTION_LABEL) {
                            localPendingPrescription.value = true;
                            const isPrescriptionLensOption = (_h = (_g = props.product) === null || _g === void 0 ? void 0 : _g.options) === null || _h === void 0 ? void 0 : _h.find((option) => option.title === IS_PRESCRIPTION_LENS_BUNDLE_OPTION_LABEL);
                            const isPrescriptionLensOptionValue = customizedOptions.value.get(isPrescriptionLensOption.uid);
                            // clear customized options as we can have a previous prescription
                            customizedOptions.value.clear();
                            customizedOptions.value.set(isPrescriptionLensOption.uid, isPrescriptionLensOptionValue);
                        }
                        else {
                            customizedOptions.value.set(isCustomField.uid, { uid: isCustomField.uid, value: true });
                        }
                    }
                    if (editMode.value && selectedCard !== PENDING_PRESCRIPTION_BUNDLE_OPTION_LABEL) {
                        setValidPrescription(false);
                    }
                    break;
                default:
                    break;
            }
        };
        const handleData = (data) => {
            switch (data.id) {
                case 'prescription-manual':
                    const formattedPrescription = formatPrescriptionForMagento();
                    localPendingPrescription.value = false;
                    formattedPrescription.forEach((prescriptionValue) => {
                        var _a, _b;
                        const isCustomField = (_b = (_a = props.product) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.find((option) => option.title === prescriptionValue[0]);
                        if (isCustomField) {
                            customizedOptions.value.set(isCustomField.uid, { uid: isCustomField.uid, value: prescriptionValue[1] });
                        }
                    });
                    break;
                default:
                    break;
            }
        };
        const route = useRoute();
        // If we want to edit an item, we should use a route query like `?editItem=item.uid`
        watch([cart, product], ([newCartValue, newProductValue]) => {
            var _a, _b, _c, _d;
            if (newProductValue === null || newProductValue === void 0 ? void 0 : newProductValue.sku)
                checkIsPreorder();
            if ((newCartValue === null || newCartValue === void 0 ? void 0 : newCartValue.items) && (newProductValue === null || newProductValue === void 0 ? void 0 : newProductValue.items) && ((_a = route.value.query) === null || _a === void 0 ? void 0 : _a.editItem)) {
                editItem.value = newCartValue.items.find((item) => item.uid === route.value.query.editItem);
                if (((_b = route.value.query) === null || _b === void 0 ? void 0 : _b.editItem) && editItem.value) {
                    editMode.value = true;
                    // Here we detect that we are editing an item. We need to set the prescription data and/or set
                    // We should simulate a user flow to populate all data:
                    const isGooseProduct = newProductValue.sku.includes('goose');
                    // 1. prescription-type-select group
                    const isPrescriptionLensOption = editItem.value.customizable_options.find((option) => option.label === IS_PRESCRIPTION_LENS_BUNDLE_OPTION_LABEL);
                    const isPrescriptionLens = ((_c = isPrescriptionLensOption === null || isPrescriptionLensOption === void 0 ? void 0 : isPrescriptionLensOption.values[0]) === null || _c === void 0 ? void 0 : _c.value) === 'true';
                    if (isPrescriptionLens) {
                        const lensType = isGooseProduct ? PRESCRIPTION_GOOSE_CARD_ID : PRESCRIPTION_CARD_ID;
                        setGroupData({ groupId: 'prescription-type-select', data: { select: lensType }, nextEvent: 'add-prescription' });
                        handleSelection({
                            id: 'prescription-type-select',
                            select: lensType,
                        });
                        // 2. add-prescription group
                        const pendingPrescriptionOption = editItem.value.customizable_options.find((option) => option.label === PENDING_PRESCRIPTION_BUNDLE_OPTION_LABEL);
                        const isAddPrescriptionLater = ((_d = pendingPrescriptionOption === null || pendingPrescriptionOption === void 0 ? void 0 : pendingPrescriptionOption.values[0]) === null || _d === void 0 ? void 0 : _d.value) === 'true';
                        if (isAddPrescriptionLater) {
                            setGroupData({ groupId: 'add-prescription', data: { select: PROVIDE_PRESCRIPTION_LATER_CARD_ID }, nextEvent: 'select-lens-coating' });
                            handleSelection({
                                id: 'add-prescription',
                                select: PROVIDE_PRESCRIPTION_LATER_CARD_ID,
                            });
                        }
                        else {
                            setGroupData({ groupId: 'add-prescription', data: { select: ENTER_PRESCRIPTION_MANUALLY_CARD_ID } });
                            handleSelection({
                                id: 'add-prescription',
                                select: ENTER_PRESCRIPTION_MANUALLY_CARD_ID,
                            });
                            // 3. prescription info
                            const magentoPrescription = getPrescriptionFromMagento(editItem.value.customizable_options);
                            setPrescription(magentoPrescription);
                            /* Don't set prescription step if add prescription later */
                            if (!isAddPrescriptionLater)
                                setGroupData({ groupId: 'prescription-manual', data: { ...magentoPrescription, select: '' } });
                            handleData({
                                id: 'prescription-manual',
                                data: undefined,
                                select: '',
                            });
                        }
                    }
                    else {
                        const lensType = isGooseProduct ? NON_PRESCRIPTION_GOOSE_CARD_ID : NON_PRESCRIPTION_CARD_ID;
                        setGroupData({ groupId: 'prescription-type-select', data: { select: lensType }, nextEvent: 'select-lens-coating' });
                        handleSelection({
                            id: 'prescription-type-select',
                            select: lensType,
                        });
                    }
                    // 4. lens coating
                    const lensCoatingOptions = editItem.value.bundle_options[1].values.map((value) => {
                        var _a;
                        const productInfo = newProductValue.items[1].options.find((option) => option.product.name === value.label);
                        return { ...value, sku: (_a = productInfo === null || productInfo === void 0 ? void 0 : productInfo.product) === null || _a === void 0 ? void 0 : _a.sku };
                    }).filter(Boolean).filter((productItem) => !productItem.sku.includes('VR7000')); // we have to remove frames to get the selected coating
                    if (lensCoatingOptions.length > 0) {
                        setGroupData({ groupId: 'select-lens-coating', data: { select: lensCoatingOptions[0].sku } });
                        handleSelection({
                            id: 'select-lens-coating',
                            select: lensCoatingOptions[0].sku,
                        });
                    }
                }
                // trigger a country search by store code to cache regions
                searchCountry({ id: $cookies.get('vsf-store').toUpperCase() });
            }
        });
        // in case the product is already loaded and watch does not trigger
        checkIsPreorder();
        return {
            loading,
            editMode,
            addItem,
            addItemToWishlist: addOrRemoveItem,
            isPrescriptionValid,
            canAddToCart,
            computedLoading,
            pageTitle,
            isAuthenticated,
            showPreOrderBanner,
            isInWishlist: computed(() => isInWishlist({ product: props.product })),
            productShortDescription,
            productDescription,
            productGallery,
            getProductName,
            productPrice,
            productSpecialPrice,
            qty,
            imageSizes,
            addToCartError,
            addToCartItem,
            modalData,
            handleSelection,
            handleData,
            modal,
            editItem,
            prices: getProductPrice(props.product),
            addToCartLoading,
            order,
        };
    },
    head() {
        return {
            title: this.pageTitle,
        };
    },
});
