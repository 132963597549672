import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { ZnButton } from '~/modules/b2b/components';
import { useUrlLanguageParser } from '~/modules/b2b/localization/composables';
import { useOrder } from '~/modules/b2b/checkout/composables';
import { GTM_EVENTS } from '~/modules/b2b/constants.client';
export default defineComponent({
    name: 'ZnPromoCard',
    components: {
        ZnButton,
    },
    props: {
        promoCode: {
            type: String,
            default: 'loading...',
        },
    },
    setup() {
        const { sendGTMEvent } = useOrder();
        const { storeCode } = useUrlLanguageParser();
        const goToZenni = ref(null);
        const storeUrl = computed(() => (storeCode.toUpperCase() === 'CA' ? 'https://ca.zennioptical.com/' : 'https://www.zennioptical.com/'));
        const goTo = () => {
            sendGTMEvent({ event: GTM_EVENTS.SHOP_AT_ZENNI_CLICK });
            goToZenni.value.click();
        };
        return {
            goTo,
            goToZenni,
            storeUrl,
        };
    },
});
