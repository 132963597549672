import { onSSR } from '@vue-storefront/core';
import { defineComponent, computed, onMounted } from '@nuxtjs/composition-api';
import { useContentful } from '~/composables';
import Accordion from './Accordion.vue';
export default defineComponent({
    name: 'Faq',
    components: {
        Accordion,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const { search, content } = useContentful('pdpFaqComponent');
        const { search: seoSearch, content: seoContent } = useContentful('seoFaq');
        const getContentfulData = async () => {
            await search({
                custom: {
                    type: 'pdpFaqComponent',
                    field: 'sku',
                    value: props.product.sku,
                },
            });
        };
        const getContentfulSeoData = async () => {
            await seoSearch({
                custom: {
                    type: 'seoFaq',
                    field: 'sku',
                    value: props.product.sku,
                },
            });
        };
        onSSR(async () => {
            await Promise.all([getContentfulData(), getContentfulSeoData()]);
        });
        onMounted(async () => {
            if (content.value.length === 0) {
                await getContentfulData();
            }
            if (seoContent.value.length === 0) {
                await getContentfulSeoData();
            }
        });
        const faqData = computed(() => { var _a; return (_a = content.value[0]) === null || _a === void 0 ? void 0 : _a.fields; });
        const seoFaqData = computed(() => { var _a; return (_a = seoContent.value[0]) === null || _a === void 0 ? void 0 : _a.fields; });
        return {
            faqData,
            seoFaqData,
        };
    },
    head() {
        var _a, _b;
        const entities = ((_b = (_a = this.seoFaqData) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : []).map((item) => ({
            '@type': 'Question',
            name: item.fields.question,
            acceptedAnswer: {
                '@type': 'Answer',
                text: item.fields.answer,
            },
        }));
        const jsonld = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: entities,
        };
        return {
            script: [{
                    type: 'application/ld+json',
                    innerHTML: JSON.stringify(jsonld), // <- set jsonld object in data or wherever you want
                }],
            __dangerouslyDisableSanitizers: ['script'], // <- this is important
        };
    },
});
