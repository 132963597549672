

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"us","numberFormats":{"us":{"currency":{"style":"currency","currency":"USD","currencyDisplay":"symbol"}},"ca":{"currency":{"style":"currency","currency":"CAD","currencyDisplay":"symbol"}},"gb":{"currency":{"style":"currency","currency":"GBP","currencyDisplay":"symbol"}},"jp":{"currency":{"style":"currency","currency":"JPY","currencyDisplay":"symbol"}},"eu":{"currency":{"style":"currency","currency":"EUR","currencyDisplay":"symbol"}},"au":{"currency":{"style":"currency","currency":"AUD","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: ["us","us/en","ca/en","ca/fr","jp/ja","jp/en","gb/gb","gb/en","eu/en","eu/fr","eu/de","au/au","au/en"],
  defaultLocale: "us",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: false,
  langDir: null,
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "https://vr.zenni.io/",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  country: "US",
  normalizedLocales: [{"code":"us"},{"code":"us/en"},{"code":"ca/en"},{"code":"ca/fr"},{"code":"jp/ja"},{"code":"jp/en"},{"code":"gb/gb"},{"code":"gb/en"},{"code":"eu/en"},{"code":"eu/fr"},{"code":"eu/de"},{"code":"au/au"},{"code":"au/en"}],
  localeCodes: ["us","us/en","ca/en","ca/fr","jp/ja","jp/en","gb/gb","gb/en","eu/en","eu/fr","eu/de","au/au","au/en"],
}

export const localeMessages = {}
