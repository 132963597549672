import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _67b76d36 = () => interopDefault(import('../modules/b2b/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _45a7fa0b = () => interopDefault(import('../modules/b2b/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _2b3d9a62 = () => interopDefault(import('../modules/b2b/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _44f4f902 = () => interopDefault(import('../modules/b2b/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _1e1d3774 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _9d247bce = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _4f966e87 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _631ece7d = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _1faea343 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _6030f5f2 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _2c3e1489 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _6b2f5bc7 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _741e507c = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _ad0c36da = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _d47082b2 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _5ce6c4cc = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _af8cda7e = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _0f655244 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _70688cd3 = () => interopDefault(import('../modules/b2b/pages/prescription/ocr.vue' /* webpackChunkName: "" */))
const _6da06a0c = () => interopDefault(import('../modules/b2b/checkout/pages/Confirmation.vue' /* webpackChunkName: "" */))
const _5f9a67f4 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _6eca7b47 = () => interopDefault(import('../modules/b2b/pages/prescription/index.vue' /* webpackChunkName: "" */))

const _ecf51d3e = () => interopDefault(import('../modules/b2b/pages/install.vue' /* webpackChunkName: "" */))
const _657c9ba0 = () => interopDefault(import('../modules/b2b/pages/install-antifog.vue' /* webpackChunkName: "" */))
const _6dd806d2 = () => interopDefault(import('../modules/b2b/pages/order/index.vue' /* webpackChunkName: "" */))
const _ce67286e = () => interopDefault(import('../modules/b2b/pages/redeem/index.vue' /* webpackChunkName: "" */))
const _6247c270 = () => interopDefault(import('../modules/b2b/pages/order/prescription/EnterPrescription.vue' /* webpackChunkName: "" */))
const _5e66910f = () => interopDefault(import('../modules/b2b/pages/order/prescription/ThankYou.vue' /* webpackChunkName: "" */))
const _7e49fa54 = () => interopDefault(import('../modules/b2b/pages/redeem/cancel/index.vue' /* webpackChunkName: "" */))
const _673ea9f0 = () => interopDefault(import('../modules/b2b/pages/redeem/order/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart",
    component: _67b76d36,
    name: "cart___us"
  }, {
    path: "/checkout",
    component: _45a7fa0b,
    name: "checkout___us",
    children: [{
      path: "payment",
      component: _2b3d9a62,
      name: "payment___us"
    }, {
      path: "shipping",
      component: _44f4f902,
      name: "shipping___us"
    }]
  }, {
    path: "/Cms",
    component: _1e1d3774,
    name: "Cms___us"
  }, {
    path: "/customer",
    component: _9d247bce,
    meta: {"titleLabel":"My Account"},
    name: "customer___us",
    children: [{
      path: "addresses-details",
      component: _4f966e87,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___us"
    }, {
      path: "my-newsletter",
      component: _631ece7d,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___us"
    }, {
      path: "my-profile",
      component: _1faea343,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___us"
    }, {
      path: "my-reviews",
      component: _6030f5f2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___us"
    }, {
      path: "my-wishlist",
      component: _2c3e1489,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___us"
    }, {
      path: "order-history",
      component: _6b2f5bc7,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___us"
    }, {
      path: "/reset-password",
      component: _741e507c,
      alias: "/customer/account/createPassword",
      name: "reset-password___us"
    }, {
      path: "addresses-details/create",
      component: _ad0c36da,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___us"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _d47082b2,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___us"
    }, {
      path: "order-history/:orderId",
      component: _5ce6c4cc,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___us"
    }]
  }, {
    path: "/Faq",
    component: _af8cda7e,
    name: "Faq___us"
  }, {
    path: "/Home",
    component: _0f655244,
    name: "Home___us"
  }, {
    path: "/ocr",
    component: _70688cd3,
    name: "ocr___us"
  }, {
    path: "/order-confirmation",
    component: _6da06a0c,
    name: "order-confirmation___us"
  }, {
    path: "/Page",
    component: _5f9a67f4,
    name: "Page___us"
  }, {
    path: "/prescription",
    component: _6eca7b47,
    name: "prescription___us"
  }, {
    path: "/us/",
    redirect: "/us/quest/products/quest-3"
  }, {
    path: "/au/au/",
    redirect: "/au/au/quest/products/quest-3"
  }, {
    path: "/au/au/cart",
    component: _67b76d36,
    name: "cart___au/au"
  }, {
    path: "/au/au/checkout",
    component: _45a7fa0b,
    name: "checkout___au/au",
    children: [{
      path: "payment",
      component: _2b3d9a62,
      name: "payment___au/au"
    }, {
      path: "shipping",
      component: _44f4f902,
      name: "shipping___au/au"
    }]
  }, {
    path: "/au/au/Cms",
    component: _1e1d3774,
    name: "Cms___au/au"
  }, {
    path: "/au/au/customer",
    component: _9d247bce,
    meta: {"titleLabel":"My Account"},
    name: "customer___au/au",
    children: [{
      path: "addresses-details",
      component: _4f966e87,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___au/au"
    }, {
      path: "my-newsletter",
      component: _631ece7d,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___au/au"
    }, {
      path: "my-profile",
      component: _1faea343,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___au/au"
    }, {
      path: "my-reviews",
      component: _6030f5f2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___au/au"
    }, {
      path: "my-wishlist",
      component: _2c3e1489,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___au/au"
    }, {
      path: "order-history",
      component: _6b2f5bc7,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___au/au"
    }, {
      path: "addresses-details/create",
      component: _ad0c36da,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___au/au"
    }, {
      path: "/au/au/reset-password",
      component: _741e507c,
      alias: "/customer/account/createPassword",
      name: "reset-password___au/au"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _d47082b2,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___au/au"
    }, {
      path: "order-history/:orderId",
      component: _5ce6c4cc,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___au/au"
    }]
  }, {
    path: "/au/au/Faq",
    component: _af8cda7e,
    name: "Faq___au/au"
  }, {
    path: "/au/au/Home",
    component: _0f655244,
    name: "Home___au/au"
  }, {
    path: "/au/au/ocr",
    component: _70688cd3,
    name: "ocr___au/au"
  }, {
    path: "/au/au/order-confirmation",
    component: _6da06a0c,
    name: "order-confirmation___au/au"
  }, {
    path: "/au/au/Page",
    component: _5f9a67f4,
    name: "Page___au/au"
  }, {
    path: "/au/au/prescription",
    component: _6eca7b47,
    name: "prescription___au/au"
  }, {
    path: "/au/en/",
    redirect: "/au/en/quest/products/quest-3"
  }, {
    path: "/au/en/cart",
    component: _67b76d36,
    name: "cart___au/en"
  }, {
    path: "/au/en/checkout",
    component: _45a7fa0b,
    name: "checkout___au/en",
    children: [{
      path: "payment",
      component: _2b3d9a62,
      name: "payment___au/en"
    }, {
      path: "shipping",
      component: _44f4f902,
      name: "shipping___au/en"
    }]
  }, {
    path: "/au/en/Cms",
    component: _1e1d3774,
    name: "Cms___au/en"
  }, {
    path: "/au/en/customer",
    component: _9d247bce,
    meta: {"titleLabel":"My Account"},
    name: "customer___au/en",
    children: [{
      path: "addresses-details",
      component: _4f966e87,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___au/en"
    }, {
      path: "my-newsletter",
      component: _631ece7d,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___au/en"
    }, {
      path: "my-profile",
      component: _1faea343,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___au/en"
    }, {
      path: "my-reviews",
      component: _6030f5f2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___au/en"
    }, {
      path: "my-wishlist",
      component: _2c3e1489,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___au/en"
    }, {
      path: "order-history",
      component: _6b2f5bc7,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___au/en"
    }, {
      path: "addresses-details/create",
      component: _ad0c36da,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___au/en"
    }, {
      path: "/au/en/reset-password",
      component: _741e507c,
      alias: "/customer/account/createPassword",
      name: "reset-password___au/en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _d47082b2,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___au/en"
    }, {
      path: "order-history/:orderId",
      component: _5ce6c4cc,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___au/en"
    }]
  }, {
    path: "/au/en/Faq",
    component: _af8cda7e,
    name: "Faq___au/en"
  }, {
    path: "/au/en/Home",
    component: _0f655244,
    name: "Home___au/en"
  }, {
    path: "/au/en/ocr",
    component: _70688cd3,
    name: "ocr___au/en"
  }, {
    path: "/au/en/order-confirmation",
    component: _6da06a0c,
    name: "order-confirmation___au/en"
  }, {
    path: "/au/en/Page",
    component: _5f9a67f4,
    name: "Page___au/en"
  }, {
    path: "/au/en/prescription",
    component: _6eca7b47,
    name: "prescription___au/en"
  }, {
    path: "/ca/en/",
    redirect: "/ca/en/quest/products/quest-3"
  }, {
    path: "/ca/en/cart",
    component: _67b76d36,
    name: "cart___ca/en"
  }, {
    path: "/ca/en/checkout",
    component: _45a7fa0b,
    name: "checkout___ca/en",
    children: [{
      path: "payment",
      component: _2b3d9a62,
      name: "payment___ca/en"
    }, {
      path: "shipping",
      component: _44f4f902,
      name: "shipping___ca/en"
    }]
  }, {
    path: "/ca/en/Cms",
    component: _1e1d3774,
    name: "Cms___ca/en"
  }, {
    path: "/ca/en/customer",
    component: _9d247bce,
    meta: {"titleLabel":"My Account"},
    name: "customer___ca/en",
    children: [{
      path: "addresses-details",
      component: _4f966e87,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___ca/en"
    }, {
      path: "my-newsletter",
      component: _631ece7d,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___ca/en"
    }, {
      path: "my-profile",
      component: _1faea343,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___ca/en"
    }, {
      path: "my-reviews",
      component: _6030f5f2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___ca/en"
    }, {
      path: "my-wishlist",
      component: _2c3e1489,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___ca/en"
    }, {
      path: "order-history",
      component: _6b2f5bc7,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___ca/en"
    }, {
      path: "addresses-details/create",
      component: _ad0c36da,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___ca/en"
    }, {
      path: "/ca/en/reset-password",
      component: _741e507c,
      alias: "/customer/account/createPassword",
      name: "reset-password___ca/en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _d47082b2,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___ca/en"
    }, {
      path: "order-history/:orderId",
      component: _5ce6c4cc,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___ca/en"
    }]
  }, {
    path: "/ca/en/Faq",
    component: _af8cda7e,
    name: "Faq___ca/en"
  }, {
    path: "/ca/en/Home",
    component: _0f655244,
    name: "Home___ca/en"
  }, {
    path: "/ca/en/ocr",
    component: _70688cd3,
    name: "ocr___ca/en"
  }, {
    path: "/ca/en/order-confirmation",
    component: _6da06a0c,
    name: "order-confirmation___ca/en"
  }, {
    path: "/ca/en/Page",
    component: _5f9a67f4,
    name: "Page___ca/en"
  }, {
    path: "/ca/en/prescription",
    component: _6eca7b47,
    name: "prescription___ca/en"
  }, {
    path: "/ca/fr/",
    redirect: "/ca/fr/quest/products/quest-3"
  }, {
    path: "/ca/fr/cart",
    component: _67b76d36,
    name: "cart___ca/fr"
  }, {
    path: "/ca/fr/checkout",
    component: _45a7fa0b,
    name: "checkout___ca/fr",
    children: [{
      path: "payment",
      component: _2b3d9a62,
      name: "payment___ca/fr"
    }, {
      path: "shipping",
      component: _44f4f902,
      name: "shipping___ca/fr"
    }]
  }, {
    path: "/ca/fr/Cms",
    component: _1e1d3774,
    name: "Cms___ca/fr"
  }, {
    path: "/ca/fr/customer",
    component: _9d247bce,
    meta: {"titleLabel":"My Account"},
    name: "customer___ca/fr",
    children: [{
      path: "addresses-details",
      component: _4f966e87,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___ca/fr"
    }, {
      path: "my-newsletter",
      component: _631ece7d,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___ca/fr"
    }, {
      path: "my-profile",
      component: _1faea343,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___ca/fr"
    }, {
      path: "my-reviews",
      component: _6030f5f2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___ca/fr"
    }, {
      path: "my-wishlist",
      component: _2c3e1489,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___ca/fr"
    }, {
      path: "order-history",
      component: _6b2f5bc7,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___ca/fr"
    }, {
      path: "addresses-details/create",
      component: _ad0c36da,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___ca/fr"
    }, {
      path: "/ca/fr/reset-password",
      component: _741e507c,
      alias: "/customer/account/createPassword",
      name: "reset-password___ca/fr"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _d47082b2,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___ca/fr"
    }, {
      path: "order-history/:orderId",
      component: _5ce6c4cc,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___ca/fr"
    }]
  }, {
    path: "/ca/fr/Faq",
    component: _af8cda7e,
    name: "Faq___ca/fr"
  }, {
    path: "/ca/fr/Home",
    component: _0f655244,
    name: "Home___ca/fr"
  }, {
    path: "/ca/fr/ocr",
    component: _70688cd3,
    name: "ocr___ca/fr"
  }, {
    path: "/ca/fr/order-confirmation",
    component: _6da06a0c,
    name: "order-confirmation___ca/fr"
  }, {
    path: "/ca/fr/Page",
    component: _5f9a67f4,
    name: "Page___ca/fr"
  }, {
    path: "/ca/fr/prescription",
    component: _6eca7b47,
    name: "prescription___ca/fr"
  }, {
    path: "/eu/de/",
    redirect: "/eu/de/quest/products/quest-3"
  }, {
    path: "/eu/de/cart",
    component: _67b76d36,
    name: "cart___eu/de"
  }, {
    path: "/eu/de/checkout",
    component: _45a7fa0b,
    name: "checkout___eu/de",
    children: [{
      path: "payment",
      component: _2b3d9a62,
      name: "payment___eu/de"
    }, {
      path: "shipping",
      component: _44f4f902,
      name: "shipping___eu/de"
    }]
  }, {
    path: "/eu/de/Cms",
    component: _1e1d3774,
    name: "Cms___eu/de"
  }, {
    path: "/eu/de/customer",
    component: _9d247bce,
    meta: {"titleLabel":"My Account"},
    name: "customer___eu/de",
    children: [{
      path: "addresses-details",
      component: _4f966e87,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___eu/de"
    }, {
      path: "my-newsletter",
      component: _631ece7d,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___eu/de"
    }, {
      path: "my-profile",
      component: _1faea343,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___eu/de"
    }, {
      path: "my-reviews",
      component: _6030f5f2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___eu/de"
    }, {
      path: "my-wishlist",
      component: _2c3e1489,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___eu/de"
    }, {
      path: "order-history",
      component: _6b2f5bc7,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___eu/de"
    }, {
      path: "addresses-details/create",
      component: _ad0c36da,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___eu/de"
    }, {
      path: "/eu/de/reset-password",
      component: _741e507c,
      alias: "/customer/account/createPassword",
      name: "reset-password___eu/de"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _d47082b2,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___eu/de"
    }, {
      path: "order-history/:orderId",
      component: _5ce6c4cc,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___eu/de"
    }]
  }, {
    path: "/eu/de/Faq",
    component: _af8cda7e,
    name: "Faq___eu/de"
  }, {
    path: "/eu/de/Home",
    component: _0f655244,
    name: "Home___eu/de"
  }, {
    path: "/eu/de/ocr",
    component: _70688cd3,
    name: "ocr___eu/de"
  }, {
    path: "/eu/de/order-confirmation",
    component: _6da06a0c,
    name: "order-confirmation___eu/de"
  }, {
    path: "/eu/de/Page",
    component: _5f9a67f4,
    name: "Page___eu/de"
  }, {
    path: "/eu/de/prescription",
    component: _6eca7b47,
    name: "prescription___eu/de"
  }, {
    path: "/eu/en/",
    redirect: "/eu/en/quest/products/quest-3"
  }, {
    path: "/eu/en/cart",
    component: _67b76d36,
    name: "cart___eu/en"
  }, {
    path: "/eu/en/checkout",
    component: _45a7fa0b,
    name: "checkout___eu/en",
    children: [{
      path: "payment",
      component: _2b3d9a62,
      name: "payment___eu/en"
    }, {
      path: "shipping",
      component: _44f4f902,
      name: "shipping___eu/en"
    }]
  }, {
    path: "/eu/en/Cms",
    component: _1e1d3774,
    name: "Cms___eu/en"
  }, {
    path: "/eu/en/customer",
    component: _9d247bce,
    meta: {"titleLabel":"My Account"},
    name: "customer___eu/en",
    children: [{
      path: "addresses-details",
      component: _4f966e87,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___eu/en"
    }, {
      path: "my-newsletter",
      component: _631ece7d,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___eu/en"
    }, {
      path: "my-profile",
      component: _1faea343,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___eu/en"
    }, {
      path: "my-reviews",
      component: _6030f5f2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___eu/en"
    }, {
      path: "my-wishlist",
      component: _2c3e1489,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___eu/en"
    }, {
      path: "order-history",
      component: _6b2f5bc7,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___eu/en"
    }, {
      path: "addresses-details/create",
      component: _ad0c36da,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___eu/en"
    }, {
      path: "/eu/en/reset-password",
      component: _741e507c,
      alias: "/customer/account/createPassword",
      name: "reset-password___eu/en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _d47082b2,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___eu/en"
    }, {
      path: "order-history/:orderId",
      component: _5ce6c4cc,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___eu/en"
    }]
  }, {
    path: "/eu/en/Faq",
    component: _af8cda7e,
    name: "Faq___eu/en"
  }, {
    path: "/eu/en/Home",
    component: _0f655244,
    name: "Home___eu/en"
  }, {
    path: "/eu/en/ocr",
    component: _70688cd3,
    name: "ocr___eu/en"
  }, {
    path: "/eu/en/order-confirmation",
    component: _6da06a0c,
    name: "order-confirmation___eu/en"
  }, {
    path: "/eu/en/Page",
    component: _5f9a67f4,
    name: "Page___eu/en"
  }, {
    path: "/eu/en/prescription",
    component: _6eca7b47,
    name: "prescription___eu/en"
  }, {
    path: "/eu/fr/",
    redirect: "/eu/fr/quest/products/quest-3"
  }, {
    path: "/eu/fr/cart",
    component: _67b76d36,
    name: "cart___eu/fr"
  }, {
    path: "/eu/fr/checkout",
    component: _45a7fa0b,
    name: "checkout___eu/fr",
    children: [{
      path: "payment",
      component: _2b3d9a62,
      name: "payment___eu/fr"
    }, {
      path: "shipping",
      component: _44f4f902,
      name: "shipping___eu/fr"
    }]
  }, {
    path: "/eu/fr/Cms",
    component: _1e1d3774,
    name: "Cms___eu/fr"
  }, {
    path: "/eu/fr/customer",
    component: _9d247bce,
    meta: {"titleLabel":"My Account"},
    name: "customer___eu/fr",
    children: [{
      path: "addresses-details",
      component: _4f966e87,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___eu/fr"
    }, {
      path: "my-newsletter",
      component: _631ece7d,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___eu/fr"
    }, {
      path: "my-profile",
      component: _1faea343,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___eu/fr"
    }, {
      path: "my-reviews",
      component: _6030f5f2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___eu/fr"
    }, {
      path: "my-wishlist",
      component: _2c3e1489,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___eu/fr"
    }, {
      path: "order-history",
      component: _6b2f5bc7,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___eu/fr"
    }, {
      path: "addresses-details/create",
      component: _ad0c36da,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___eu/fr"
    }, {
      path: "/eu/fr/reset-password",
      component: _741e507c,
      alias: "/customer/account/createPassword",
      name: "reset-password___eu/fr"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _d47082b2,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___eu/fr"
    }, {
      path: "order-history/:orderId",
      component: _5ce6c4cc,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___eu/fr"
    }]
  }, {
    path: "/eu/fr/Faq",
    component: _af8cda7e,
    name: "Faq___eu/fr"
  }, {
    path: "/eu/fr/Home",
    component: _0f655244,
    name: "Home___eu/fr"
  }, {
    path: "/eu/fr/ocr",
    component: _70688cd3,
    name: "ocr___eu/fr"
  }, {
    path: "/eu/fr/order-confirmation",
    component: _6da06a0c,
    name: "order-confirmation___eu/fr"
  }, {
    path: "/eu/fr/Page",
    component: _5f9a67f4,
    name: "Page___eu/fr"
  }, {
    path: "/eu/fr/prescription",
    component: _6eca7b47,
    name: "prescription___eu/fr"
  }, {
    path: "/gb/en/",
    redirect: "/gb/en/quest/products/quest-3"
  }, {
    path: "/gb/en/cart",
    component: _67b76d36,
    name: "cart___gb/en"
  }, {
    path: "/gb/en/checkout",
    component: _45a7fa0b,
    name: "checkout___gb/en",
    children: [{
      path: "payment",
      component: _2b3d9a62,
      name: "payment___gb/en"
    }, {
      path: "shipping",
      component: _44f4f902,
      name: "shipping___gb/en"
    }]
  }, {
    path: "/gb/en/Cms",
    component: _1e1d3774,
    name: "Cms___gb/en"
  }, {
    path: "/gb/en/customer",
    component: _9d247bce,
    meta: {"titleLabel":"My Account"},
    name: "customer___gb/en",
    children: [{
      path: "addresses-details",
      component: _4f966e87,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___gb/en"
    }, {
      path: "my-newsletter",
      component: _631ece7d,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___gb/en"
    }, {
      path: "my-profile",
      component: _1faea343,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___gb/en"
    }, {
      path: "my-reviews",
      component: _6030f5f2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___gb/en"
    }, {
      path: "my-wishlist",
      component: _2c3e1489,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___gb/en"
    }, {
      path: "order-history",
      component: _6b2f5bc7,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___gb/en"
    }, {
      path: "addresses-details/create",
      component: _ad0c36da,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___gb/en"
    }, {
      path: "/gb/en/reset-password",
      component: _741e507c,
      alias: "/customer/account/createPassword",
      name: "reset-password___gb/en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _d47082b2,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___gb/en"
    }, {
      path: "order-history/:orderId",
      component: _5ce6c4cc,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___gb/en"
    }]
  }, {
    path: "/gb/en/Faq",
    component: _af8cda7e,
    name: "Faq___gb/en"
  }, {
    path: "/gb/en/Home",
    component: _0f655244,
    name: "Home___gb/en"
  }, {
    path: "/gb/en/ocr",
    component: _70688cd3,
    name: "ocr___gb/en"
  }, {
    path: "/gb/en/order-confirmation",
    component: _6da06a0c,
    name: "order-confirmation___gb/en"
  }, {
    path: "/gb/en/Page",
    component: _5f9a67f4,
    name: "Page___gb/en"
  }, {
    path: "/gb/en/prescription",
    component: _6eca7b47,
    name: "prescription___gb/en"
  }, {
    path: "/gb/gb/",
    redirect: "/gb/gb/quest/products/quest-3"
  }, {
    path: "/gb/gb/cart",
    component: _67b76d36,
    name: "cart___gb/gb"
  }, {
    path: "/gb/gb/checkout",
    component: _45a7fa0b,
    name: "checkout___gb/gb",
    children: [{
      path: "payment",
      component: _2b3d9a62,
      name: "payment___gb/gb"
    }, {
      path: "shipping",
      component: _44f4f902,
      name: "shipping___gb/gb"
    }]
  }, {
    path: "/gb/gb/Cms",
    component: _1e1d3774,
    name: "Cms___gb/gb"
  }, {
    path: "/gb/gb/customer",
    component: _9d247bce,
    meta: {"titleLabel":"My Account"},
    name: "customer___gb/gb",
    children: [{
      path: "addresses-details",
      component: _4f966e87,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___gb/gb"
    }, {
      path: "my-newsletter",
      component: _631ece7d,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___gb/gb"
    }, {
      path: "my-profile",
      component: _1faea343,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___gb/gb"
    }, {
      path: "my-reviews",
      component: _6030f5f2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___gb/gb"
    }, {
      path: "my-wishlist",
      component: _2c3e1489,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___gb/gb"
    }, {
      path: "order-history",
      component: _6b2f5bc7,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___gb/gb"
    }, {
      path: "addresses-details/create",
      component: _ad0c36da,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___gb/gb"
    }, {
      path: "/gb/gb/reset-password",
      component: _741e507c,
      alias: "/customer/account/createPassword",
      name: "reset-password___gb/gb"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _d47082b2,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___gb/gb"
    }, {
      path: "order-history/:orderId",
      component: _5ce6c4cc,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___gb/gb"
    }]
  }, {
    path: "/gb/gb/Faq",
    component: _af8cda7e,
    name: "Faq___gb/gb"
  }, {
    path: "/gb/gb/Home",
    component: _0f655244,
    name: "Home___gb/gb"
  }, {
    path: "/gb/gb/ocr",
    component: _70688cd3,
    name: "ocr___gb/gb"
  }, {
    path: "/gb/gb/order-confirmation",
    component: _6da06a0c,
    name: "order-confirmation___gb/gb"
  }, {
    path: "/gb/gb/Page",
    component: _5f9a67f4,
    name: "Page___gb/gb"
  }, {
    path: "/gb/gb/prescription",
    component: _6eca7b47,
    name: "prescription___gb/gb"
  }, {
    path: "/jp/en/",
    redirect: "/jp/en/quest/products/quest-3"
  }, {
    path: "/jp/en/cart",
    component: _67b76d36,
    name: "cart___jp/en"
  }, {
    path: "/jp/en/checkout",
    component: _45a7fa0b,
    name: "checkout___jp/en",
    children: [{
      path: "payment",
      component: _2b3d9a62,
      name: "payment___jp/en"
    }, {
      path: "shipping",
      component: _44f4f902,
      name: "shipping___jp/en"
    }]
  }, {
    path: "/jp/en/Cms",
    component: _1e1d3774,
    name: "Cms___jp/en"
  }, {
    path: "/jp/en/customer",
    component: _9d247bce,
    meta: {"titleLabel":"My Account"},
    name: "customer___jp/en",
    children: [{
      path: "addresses-details",
      component: _4f966e87,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___jp/en"
    }, {
      path: "my-newsletter",
      component: _631ece7d,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___jp/en"
    }, {
      path: "my-profile",
      component: _1faea343,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___jp/en"
    }, {
      path: "my-reviews",
      component: _6030f5f2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___jp/en"
    }, {
      path: "my-wishlist",
      component: _2c3e1489,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___jp/en"
    }, {
      path: "order-history",
      component: _6b2f5bc7,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___jp/en"
    }, {
      path: "addresses-details/create",
      component: _ad0c36da,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___jp/en"
    }, {
      path: "/jp/en/reset-password",
      component: _741e507c,
      alias: "/customer/account/createPassword",
      name: "reset-password___jp/en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _d47082b2,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___jp/en"
    }, {
      path: "order-history/:orderId",
      component: _5ce6c4cc,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___jp/en"
    }]
  }, {
    path: "/jp/en/Faq",
    component: _af8cda7e,
    name: "Faq___jp/en"
  }, {
    path: "/jp/en/Home",
    component: _0f655244,
    name: "Home___jp/en"
  }, {
    path: "/jp/en/ocr",
    component: _70688cd3,
    name: "ocr___jp/en"
  }, {
    path: "/jp/en/order-confirmation",
    component: _6da06a0c,
    name: "order-confirmation___jp/en"
  }, {
    path: "/jp/en/Page",
    component: _5f9a67f4,
    name: "Page___jp/en"
  }, {
    path: "/jp/en/prescription",
    component: _6eca7b47,
    name: "prescription___jp/en"
  }, {
    path: "/jp/ja/",
    redirect: "/jp/ja/quest/products/quest-3"
  }, {
    path: "/jp/ja/cart",
    component: _67b76d36,
    name: "cart___jp/ja"
  }, {
    path: "/jp/ja/checkout",
    component: _45a7fa0b,
    name: "checkout___jp/ja",
    children: [{
      path: "payment",
      component: _2b3d9a62,
      name: "payment___jp/ja"
    }, {
      path: "shipping",
      component: _44f4f902,
      name: "shipping___jp/ja"
    }]
  }, {
    path: "/jp/ja/Cms",
    component: _1e1d3774,
    name: "Cms___jp/ja"
  }, {
    path: "/jp/ja/customer",
    component: _9d247bce,
    meta: {"titleLabel":"My Account"},
    name: "customer___jp/ja",
    children: [{
      path: "addresses-details",
      component: _4f966e87,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___jp/ja"
    }, {
      path: "my-newsletter",
      component: _631ece7d,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___jp/ja"
    }, {
      path: "my-profile",
      component: _1faea343,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___jp/ja"
    }, {
      path: "my-reviews",
      component: _6030f5f2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___jp/ja"
    }, {
      path: "my-wishlist",
      component: _2c3e1489,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___jp/ja"
    }, {
      path: "order-history",
      component: _6b2f5bc7,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___jp/ja"
    }, {
      path: "addresses-details/create",
      component: _ad0c36da,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___jp/ja"
    }, {
      path: "/jp/ja/reset-password",
      component: _741e507c,
      alias: "/customer/account/createPassword",
      name: "reset-password___jp/ja"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _d47082b2,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___jp/ja"
    }, {
      path: "order-history/:orderId",
      component: _5ce6c4cc,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___jp/ja"
    }]
  }, {
    path: "/jp/ja/Faq",
    component: _af8cda7e,
    name: "Faq___jp/ja"
  }, {
    path: "/jp/ja/Home",
    component: _0f655244,
    name: "Home___jp/ja"
  }, {
    path: "/jp/ja/ocr",
    component: _70688cd3,
    name: "ocr___jp/ja"
  }, {
    path: "/jp/ja/order-confirmation",
    component: _6da06a0c,
    name: "order-confirmation___jp/ja"
  }, {
    path: "/jp/ja/Page",
    component: _5f9a67f4,
    name: "Page___jp/ja"
  }, {
    path: "/jp/ja/prescription",
    component: _6eca7b47,
    name: "prescription___jp/ja"
  }, {
    path: "/us/en/",
    redirect: "/us/en/quest/products/quest-3"
  }, {
    path: "/us/en/cart",
    component: _67b76d36,
    name: "cart___us/en"
  }, {
    path: "/us/en/checkout",
    component: _45a7fa0b,
    name: "checkout___us/en",
    children: [{
      path: "payment",
      component: _2b3d9a62,
      name: "payment___us/en"
    }, {
      path: "shipping",
      component: _44f4f902,
      name: "shipping___us/en"
    }]
  }, {
    path: "/us/en/Cms",
    component: _1e1d3774,
    name: "Cms___us/en"
  }, {
    path: "/us/en/customer",
    component: _9d247bce,
    meta: {"titleLabel":"My Account"},
    name: "customer___us/en",
    children: [{
      path: "addresses-details",
      component: _4f966e87,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___us/en"
    }, {
      path: "my-newsletter",
      component: _631ece7d,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___us/en"
    }, {
      path: "my-profile",
      component: _1faea343,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___us/en"
    }, {
      path: "my-reviews",
      component: _6030f5f2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___us/en"
    }, {
      path: "my-wishlist",
      component: _2c3e1489,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___us/en"
    }, {
      path: "order-history",
      component: _6b2f5bc7,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___us/en"
    }, {
      path: "addresses-details/create",
      component: _ad0c36da,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___us/en"
    }, {
      path: "/us/en/reset-password",
      component: _741e507c,
      alias: "/customer/account/createPassword",
      name: "reset-password___us/en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _d47082b2,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___us/en"
    }, {
      path: "order-history/:orderId",
      component: _5ce6c4cc,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___us/en"
    }]
  }, {
    path: "/us/en/Faq",
    component: _af8cda7e,
    name: "Faq___us/en"
  }, {
    path: "/us/en/Home",
    component: _0f655244,
    name: "Home___us/en"
  }, {
    path: "/us/en/ocr",
    component: _70688cd3,
    name: "ocr___us/en"
  }, {
    path: "/us/en/order-confirmation",
    component: _6da06a0c,
    name: "order-confirmation___us/en"
  }, {
    path: "/us/en/Page",
    component: _5f9a67f4,
    name: "Page___us/en"
  }, {
    path: "/us/en/prescription",
    component: _6eca7b47,
    name: "prescription___us/en"
  }, {
    path: "/quest/products/quest-3/install",
    component: _ecf51d3e,
    name: "install___us"
  }, {
    path: "/quest/products/quest-3/install-anti-fog",
    component: _657c9ba0,
    name: "install-anti-fog___us"
  }, {
    path: "/au/au/quest/products/quest-3/install",
    component: _ecf51d3e,
    name: "install___au/au"
  }, {
    path: "/au/au/quest/products/quest-3/install-anti-fog",
    component: _657c9ba0,
    name: "install-anti-fog___au/au"
  }, {
    path: "/au/en/quest/products/quest-3/install",
    component: _ecf51d3e,
    name: "install___au/en"
  }, {
    path: "/au/en/quest/products/quest-3/install-anti-fog",
    component: _657c9ba0,
    name: "install-anti-fog___au/en"
  }, {
    path: "/ca/en/quest/products/quest-3/install",
    component: _ecf51d3e,
    name: "install___ca/en"
  }, {
    path: "/ca/en/quest/products/quest-3/install-anti-fog",
    component: _657c9ba0,
    name: "install-anti-fog___ca/en"
  }, {
    path: "/ca/fr/quest/products/quest-3/install",
    component: _ecf51d3e,
    name: "install___ca/fr"
  }, {
    path: "/ca/fr/quest/products/quest-3/install-anti-fog",
    component: _657c9ba0,
    name: "install-anti-fog___ca/fr"
  }, {
    path: "/eu/de/quest/products/quest-3/install",
    component: _ecf51d3e,
    name: "install___eu/de"
  }, {
    path: "/eu/de/quest/products/quest-3/install-anti-fog",
    component: _657c9ba0,
    name: "install-anti-fog___eu/de"
  }, {
    path: "/eu/en/quest/products/quest-3/install",
    component: _ecf51d3e,
    name: "install___eu/en"
  }, {
    path: "/eu/en/quest/products/quest-3/install-anti-fog",
    component: _657c9ba0,
    name: "install-anti-fog___eu/en"
  }, {
    path: "/eu/fr/quest/products/quest-3/install",
    component: _ecf51d3e,
    name: "install___eu/fr"
  }, {
    path: "/eu/fr/quest/products/quest-3/install-anti-fog",
    component: _657c9ba0,
    name: "install-anti-fog___eu/fr"
  }, {
    path: "/gb/en/quest/products/quest-3/install",
    component: _ecf51d3e,
    name: "install___gb/en"
  }, {
    path: "/gb/en/quest/products/quest-3/install-anti-fog",
    component: _657c9ba0,
    name: "install-anti-fog___gb/en"
  }, {
    path: "/gb/gb/quest/products/quest-3/install",
    component: _ecf51d3e,
    name: "install___gb/gb"
  }, {
    path: "/gb/gb/quest/products/quest-3/install-anti-fog",
    component: _657c9ba0,
    name: "install-anti-fog___gb/gb"
  }, {
    path: "/jp/en/quest/products/quest-3/install",
    component: _ecf51d3e,
    name: "install___jp/en"
  }, {
    path: "/jp/en/quest/products/quest-3/install-anti-fog",
    component: _657c9ba0,
    name: "install-anti-fog___jp/en"
  }, {
    path: "/jp/ja/quest/products/quest-3/install",
    component: _ecf51d3e,
    name: "install___jp/ja"
  }, {
    path: "/jp/ja/quest/products/quest-3/install-anti-fog",
    component: _657c9ba0,
    name: "install-anti-fog___jp/ja"
  }, {
    path: "/us/en/quest/products/quest-3/install",
    component: _ecf51d3e,
    name: "install___us/en"
  }, {
    path: "/us/en/quest/products/quest-3/install-anti-fog",
    component: _657c9ba0,
    name: "install-anti-fog___us/en"
  }, {
    path: "/au/au/orders/:globalOrderId",
    component: _6dd806d2,
    name: "order___au/au"
  }, {
    path: "/au/au/redeem/:globalOrderId",
    component: _ce67286e,
    name: "redeem___au/au"
  }, {
    path: "/au/en/orders/:globalOrderId",
    component: _6dd806d2,
    name: "order___au/en"
  }, {
    path: "/au/en/redeem/:globalOrderId",
    component: _ce67286e,
    name: "redeem___au/en"
  }, {
    path: "/ca/en/orders/:globalOrderId",
    component: _6dd806d2,
    name: "order___ca/en"
  }, {
    path: "/ca/en/redeem/:globalOrderId",
    component: _ce67286e,
    name: "redeem___ca/en"
  }, {
    path: "/ca/fr/orders/:globalOrderId",
    component: _6dd806d2,
    name: "order___ca/fr"
  }, {
    path: "/ca/fr/redeem/:globalOrderId",
    component: _ce67286e,
    name: "redeem___ca/fr"
  }, {
    path: "/eu/de/orders/:globalOrderId",
    component: _6dd806d2,
    name: "order___eu/de"
  }, {
    path: "/eu/de/redeem/:globalOrderId",
    component: _ce67286e,
    name: "redeem___eu/de"
  }, {
    path: "/eu/en/orders/:globalOrderId",
    component: _6dd806d2,
    name: "order___eu/en"
  }, {
    path: "/eu/en/redeem/:globalOrderId",
    component: _ce67286e,
    name: "redeem___eu/en"
  }, {
    path: "/eu/fr/orders/:globalOrderId",
    component: _6dd806d2,
    name: "order___eu/fr"
  }, {
    path: "/eu/fr/redeem/:globalOrderId",
    component: _ce67286e,
    name: "redeem___eu/fr"
  }, {
    path: "/gb/en/orders/:globalOrderId",
    component: _6dd806d2,
    name: "order___gb/en"
  }, {
    path: "/gb/en/redeem/:globalOrderId",
    component: _ce67286e,
    name: "redeem___gb/en"
  }, {
    path: "/gb/gb/orders/:globalOrderId",
    component: _6dd806d2,
    name: "order___gb/gb"
  }, {
    path: "/gb/gb/redeem/:globalOrderId",
    component: _ce67286e,
    name: "redeem___gb/gb"
  }, {
    path: "/jp/en/orders/:globalOrderId",
    component: _6dd806d2,
    name: "order___jp/en"
  }, {
    path: "/jp/en/redeem/:globalOrderId",
    component: _ce67286e,
    name: "redeem___jp/en"
  }, {
    path: "/jp/ja/orders/:globalOrderId",
    component: _6dd806d2,
    name: "order___jp/ja"
  }, {
    path: "/jp/ja/redeem/:globalOrderId",
    component: _ce67286e,
    name: "redeem___jp/ja"
  }, {
    path: "/us/en/orders/:globalOrderId",
    component: _6dd806d2,
    name: "order___us/en"
  }, {
    path: "/us/en/redeem/:globalOrderId",
    component: _ce67286e,
    name: "redeem___us/en"
  }, {
    path: "/au/au/orders/:globalOrderId/items/:itemId/prescription",
    component: _6247c270,
    name: "enter-prescription___au/au"
  }, {
    path: "/au/en/orders/:globalOrderId/items/:itemId/prescription",
    component: _6247c270,
    name: "enter-prescription___au/en"
  }, {
    path: "/ca/en/orders/:globalOrderId/items/:itemId/prescription",
    component: _6247c270,
    name: "enter-prescription___ca/en"
  }, {
    path: "/ca/fr/orders/:globalOrderId/items/:itemId/prescription",
    component: _6247c270,
    name: "enter-prescription___ca/fr"
  }, {
    path: "/eu/de/orders/:globalOrderId/items/:itemId/prescription",
    component: _6247c270,
    name: "enter-prescription___eu/de"
  }, {
    path: "/eu/en/orders/:globalOrderId/items/:itemId/prescription",
    component: _6247c270,
    name: "enter-prescription___eu/en"
  }, {
    path: "/eu/fr/orders/:globalOrderId/items/:itemId/prescription",
    component: _6247c270,
    name: "enter-prescription___eu/fr"
  }, {
    path: "/gb/en/orders/:globalOrderId/items/:itemId/prescription",
    component: _6247c270,
    name: "enter-prescription___gb/en"
  }, {
    path: "/gb/gb/orders/:globalOrderId/items/:itemId/prescription",
    component: _6247c270,
    name: "enter-prescription___gb/gb"
  }, {
    path: "/jp/en/orders/:globalOrderId/items/:itemId/prescription",
    component: _6247c270,
    name: "enter-prescription___jp/en"
  }, {
    path: "/jp/ja/orders/:globalOrderId/items/:itemId/prescription",
    component: _6247c270,
    name: "enter-prescription___jp/ja"
  }, {
    path: "/us/en/orders/:globalOrderId/items/:itemId/prescription",
    component: _6247c270,
    name: "enter-prescription___us/en"
  }, {
    path: "/au/au/orders/:globalOrderId/items/:itemId/prescription/thankyou",
    component: _5e66910f,
    name: "thankyou-prescription___au/au"
  }, {
    path: "/au/en/orders/:globalOrderId/items/:itemId/prescription/thankyou",
    component: _5e66910f,
    name: "thankyou-prescription___au/en"
  }, {
    path: "/ca/en/orders/:globalOrderId/items/:itemId/prescription/thankyou",
    component: _5e66910f,
    name: "thankyou-prescription___ca/en"
  }, {
    path: "/ca/fr/orders/:globalOrderId/items/:itemId/prescription/thankyou",
    component: _5e66910f,
    name: "thankyou-prescription___ca/fr"
  }, {
    path: "/eu/de/orders/:globalOrderId/items/:itemId/prescription/thankyou",
    component: _5e66910f,
    name: "thankyou-prescription___eu/de"
  }, {
    path: "/eu/en/orders/:globalOrderId/items/:itemId/prescription/thankyou",
    component: _5e66910f,
    name: "thankyou-prescription___eu/en"
  }, {
    path: "/eu/fr/orders/:globalOrderId/items/:itemId/prescription/thankyou",
    component: _5e66910f,
    name: "thankyou-prescription___eu/fr"
  }, {
    path: "/gb/en/orders/:globalOrderId/items/:itemId/prescription/thankyou",
    component: _5e66910f,
    name: "thankyou-prescription___gb/en"
  }, {
    path: "/gb/gb/orders/:globalOrderId/items/:itemId/prescription/thankyou",
    component: _5e66910f,
    name: "thankyou-prescription___gb/gb"
  }, {
    path: "/jp/en/orders/:globalOrderId/items/:itemId/prescription/thankyou",
    component: _5e66910f,
    name: "thankyou-prescription___jp/en"
  }, {
    path: "/jp/ja/orders/:globalOrderId/items/:itemId/prescription/thankyou",
    component: _5e66910f,
    name: "thankyou-prescription___jp/ja"
  }, {
    path: "/us/en/orders/:globalOrderId/items/:itemId/prescription/thankyou",
    component: _5e66910f,
    name: "thankyou-prescription___us/en"
  }, {
    path: "/au/au/redeem/:globalOrderId/:itemId/cancel",
    component: _7e49fa54,
    name: "redeem-order-cancel___au/au"
  }, {
    path: "/au/au/redeem/:globalOrderId/:itemId/order",
    component: _673ea9f0,
    name: "redeem-order___au/au"
  }, {
    path: "/au/en/redeem/:globalOrderId/:itemId/cancel",
    component: _7e49fa54,
    name: "redeem-order-cancel___au/en"
  }, {
    path: "/au/en/redeem/:globalOrderId/:itemId/order",
    component: _673ea9f0,
    name: "redeem-order___au/en"
  }, {
    path: "/ca/en/redeem/:globalOrderId/:itemId/cancel",
    component: _7e49fa54,
    name: "redeem-order-cancel___ca/en"
  }, {
    path: "/ca/en/redeem/:globalOrderId/:itemId/order",
    component: _673ea9f0,
    name: "redeem-order___ca/en"
  }, {
    path: "/ca/fr/redeem/:globalOrderId/:itemId/cancel",
    component: _7e49fa54,
    name: "redeem-order-cancel___ca/fr"
  }, {
    path: "/ca/fr/redeem/:globalOrderId/:itemId/order",
    component: _673ea9f0,
    name: "redeem-order___ca/fr"
  }, {
    path: "/eu/de/redeem/:globalOrderId/:itemId/cancel",
    component: _7e49fa54,
    name: "redeem-order-cancel___eu/de"
  }, {
    path: "/eu/de/redeem/:globalOrderId/:itemId/order",
    component: _673ea9f0,
    name: "redeem-order___eu/de"
  }, {
    path: "/eu/en/redeem/:globalOrderId/:itemId/cancel",
    component: _7e49fa54,
    name: "redeem-order-cancel___eu/en"
  }, {
    path: "/eu/en/redeem/:globalOrderId/:itemId/order",
    component: _673ea9f0,
    name: "redeem-order___eu/en"
  }, {
    path: "/eu/fr/redeem/:globalOrderId/:itemId/cancel",
    component: _7e49fa54,
    name: "redeem-order-cancel___eu/fr"
  }, {
    path: "/eu/fr/redeem/:globalOrderId/:itemId/order",
    component: _673ea9f0,
    name: "redeem-order___eu/fr"
  }, {
    path: "/gb/en/redeem/:globalOrderId/:itemId/cancel",
    component: _7e49fa54,
    name: "redeem-order-cancel___gb/en"
  }, {
    path: "/gb/en/redeem/:globalOrderId/:itemId/order",
    component: _673ea9f0,
    name: "redeem-order___gb/en"
  }, {
    path: "/gb/gb/redeem/:globalOrderId/:itemId/cancel",
    component: _7e49fa54,
    name: "redeem-order-cancel___gb/gb"
  }, {
    path: "/gb/gb/redeem/:globalOrderId/:itemId/order",
    component: _673ea9f0,
    name: "redeem-order___gb/gb"
  }, {
    path: "/jp/en/redeem/:globalOrderId/:itemId/cancel",
    component: _7e49fa54,
    name: "redeem-order-cancel___jp/en"
  }, {
    path: "/jp/en/redeem/:globalOrderId/:itemId/order",
    component: _673ea9f0,
    name: "redeem-order___jp/en"
  }, {
    path: "/jp/ja/redeem/:globalOrderId/:itemId/cancel",
    component: _7e49fa54,
    name: "redeem-order-cancel___jp/ja"
  }, {
    path: "/jp/ja/redeem/:globalOrderId/:itemId/order",
    component: _673ea9f0,
    name: "redeem-order___jp/ja"
  }, {
    path: "/us/en/redeem/:globalOrderId/:itemId/cancel",
    component: _7e49fa54,
    name: "redeem-order-cancel___us/en"
  }, {
    path: "/us/en/redeem/:globalOrderId/:itemId/order",
    component: _673ea9f0,
    name: "redeem-order___us/en"
  }, {
    path: "/au/au/:slug+",
    component: _5f9a67f4,
    name: "page___au/au"
  }, {
    path: "/au/en/:slug+",
    component: _5f9a67f4,
    name: "page___au/en"
  }, {
    path: "/ca/en/:slug+",
    component: _5f9a67f4,
    name: "page___ca/en"
  }, {
    path: "/ca/fr/:slug+",
    component: _5f9a67f4,
    name: "page___ca/fr"
  }, {
    path: "/eu/de/:slug+",
    component: _5f9a67f4,
    name: "page___eu/de"
  }, {
    path: "/eu/en/:slug+",
    component: _5f9a67f4,
    name: "page___eu/en"
  }, {
    path: "/eu/fr/:slug+",
    component: _5f9a67f4,
    name: "page___eu/fr"
  }, {
    path: "/gb/en/:slug+",
    component: _5f9a67f4,
    name: "page___gb/en"
  }, {
    path: "/gb/gb/:slug+",
    component: _5f9a67f4,
    name: "page___gb/gb"
  }, {
    path: "/jp/en/:slug+",
    component: _5f9a67f4,
    name: "page___jp/en"
  }, {
    path: "/jp/ja/:slug+",
    component: _5f9a67f4,
    name: "page___jp/ja"
  }, {
    path: "/us/en/:slug+",
    component: _5f9a67f4,
    name: "page___us/en"
  }, {
    path: "/orders/:globalOrderId",
    component: _6dd806d2,
    name: "order___us"
  }, {
    path: "/redeem/:globalOrderId",
    component: _ce67286e,
    name: "redeem___us"
  }, {
    path: "/orders/:globalOrderId/items/:itemId/prescription",
    component: _6247c270,
    name: "enter-prescription___us"
  }, {
    path: "/orders/:globalOrderId/items/:itemId/prescription/thankyou",
    component: _5e66910f,
    name: "thankyou-prescription___us"
  }, {
    path: "/redeem/:globalOrderId/:itemId/cancel",
    component: _7e49fa54,
    name: "redeem-order-cancel___us"
  }, {
    path: "/redeem/:globalOrderId/:itemId/order",
    component: _673ea9f0,
    name: "redeem-order___us"
  }, {
    path: "/",
    redirect: "/quest/products/quest-3"
  }, {
    path: "/:slug+",
    component: _5f9a67f4,
    name: "page___us"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
