import { computed, reactive, useContext, ref, } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { PENDING_PRESCRIPTION_BUNDLE_OPTION_LABEL, IS_PRESCRIPTION_LENS_BUNDLE_OPTION_LABEL, PAYMENT_GATEWAYS_MAP, VOUCHER_ORDER_TYPE, VOUCHER_FLOW_PARTNER_ID, VR_FLOW_PARTNER_ID, PRE_ORDER_DATES, QUEST_3_BUNDLE_SKU, QUEST_GOOSE_BUNDLE_SKU, } from '~/modules/b2b/constants.client';
import { useGuestUser } from '../../customer/composables';
import { mapProductItemToGTMProduct } from './utils';
const state = reactive({
    order: null,
    couponCode: null,
});
export const useOrder = () => {
    const { $gtm } = useContext();
    const { userId } = useGuestUser();
    const gtmPrescriptionMethodChosen = ref('manual');
    const getCustomizableOptionsFromOrder = (bundleItem) => {
        const customizableOptionsMapper = [
            ['is_prescription', IS_PRESCRIPTION_LENS_BUNDLE_OPTION_LABEL],
            ['pending_prescription', PENDING_PRESCRIPTION_BUNDLE_OPTION_LABEL],
            ['od_sphere', 'OD.Sphere'],
            ['os_sphere', 'OS.Sphere'],
            ['od_cylinder', 'OD.Cylinder'],
            ['os_cylinder', 'OS.Cylinder'],
            ['od_axis', 'OD.Axis'],
            ['os_axis', 'OS.Axis'],
            ['od_base_vertical_direction', 'OD.BaseVer'],
            ['os_base_vertical_direction', 'OS.BaseVer'],
            ['od_base_horizontal_direction', 'OD.BaseHor'],
            ['os_base_horizontal_direction', 'OS.BaseHor'],
            ['od_prism_horizontal_value', 'OD.PrismHor'],
            ['os_prism_horizontal_value', 'OS.PrismHor'],
            ['od_prism_vertical_value', 'OD.PrismVer'],
            ['os_prism_vertical_value', 'OS.PrismVer'],
            ['pd_type', 'LensType'],
            ['nv_add', 'OS.Add'],
            ['nv_add', 'OD.Add'],
            ['pd_left', 'OS.PD'],
            ['pd_right', 'OD.PD'],
            ['prescription_request_id', 'PrescriptionRequestId'],
        ];
        return customizableOptionsMapper.map(([key, label]) => {
            var _a;
            const value = (key in bundleItem.prescription) ? (_a = bundleItem.prescription[key]) === null || _a === void 0 ? void 0 : _a.toString() : undefined;
            return value ? {
                label,
                values: [{ value }],
            } : [];
        });
    };
    const fetchOrder = async (globalOrderId) => {
        const magentoOrderUrl = `/order-magento?globalOrderId=${globalOrderId}`;
        const magentoOrderReq = await fetch(magentoOrderUrl);
        return magentoOrderReq.json();
    };
    const cancelledStatuses = new Set([
        'canceled',
        'cancelled',
    ]);
    const statusToQueryFF = new Set([
        // 'pending',
        ...cancelledStatuses.values(),
        'processing',
        'shipped',
        'returned',
    ]);
    const isItemPendingPrescription = (item) => {
        var _a, _b;
        const customPendingPrescriptoinOption = item.customizable_options.find((option) => option.label === PENDING_PRESCRIPTION_BUNDLE_OPTION_LABEL);
        return ((_b = (_a = customPendingPrescriptoinOption === null || customPendingPrescriptoinOption === void 0 ? void 0 : customPendingPrescriptoinOption.values) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.value) === 'true';
    };
    const getOrder = async (globalOrderId, withoutFulfillment = false) => {
        var _a, _b, _c, _d, _e, _f;
        if (globalOrderId) {
            try {
                let paymentDetails;
                let fulfillmentOrder;
                const { magentoOrder } = await fetchOrder(globalOrderId);
                if (magentoOrder.orderType === VOUCHER_ORDER_TYPE // if the order is a voucher, we need to check item level status to show them as cancelled
                    || (magentoOrder.status && statusToQueryFF.has(magentoOrder.status.toLowerCase()) && !withoutFulfillment)) {
                    try {
                        const url = `/order?globalOrderId=${globalOrderId}`;
                        const orderReq = await fetch(url);
                        ({ fulfillmentOrder } = await orderReq.json());
                        // if we cannot query both order, we will continue with magento order only
                        [paymentDetails] = ((_a = fulfillmentOrder === null || fulfillmentOrder === void 0 ? void 0 : fulfillmentOrder.payment_details) === null || _a === void 0 ? void 0 : _a.payments) || [undefined];
                    }
                    catch (err) {
                        console.log('Failed to get order with global order id', err);
                        // throw err;
                    }
                }
                if (!magentoOrder)
                    return false;
                const products = magentoOrder.bundleItems.map((item) => {
                    var _a;
                    const ffPkg = (_a = fulfillmentOrder === null || fulfillmentOrder === void 0 ? void 0 : fulfillmentOrder.packages[0]) === null || _a === void 0 ? void 0 : _a.items.find((fulfillmentItem) => fulfillmentItem.id === item.id);
                    return {
                        prices: {
                            row_total: {
                                value: item.price,
                            },
                        },
                        product: {
                            name: item.name,
                            thumbnail: {
                                url: item.thumbnail || 'temp-beast-product.jpg',
                            },
                            image_thumbnail: item.thumbnail || 'temp-beast-product.jpg',
                        },
                        bundle_options: [{
                                values: item.items.map((bundleItem) => ({
                                    id: bundleItem.sku,
                                    label: bundleItem.name,
                                    price: bundleItem.price,
                                    discount: bundleItem.discount < 0 ? bundleItem.discount : -bundleItem.discount,
                                    tax: bundleItem.tax,
                                })),
                            }],
                        quantity: item.quantity || 1,
                        customizable_options: getCustomizableOptionsFromOrder(item),
                        uid: item.id,
                        status: ffPkg === null || ffPkg === void 0 ? void 0 : ffPkg.status,
                        timeCancelRequested: ffPkg === null || ffPkg === void 0 ? void 0 : ffPkg.time_cancel_requested,
                        partnerId: ffPkg === null || ffPkg === void 0 ? void 0 : ffPkg.partner_id,
                        sku: item.itemSku,
                    };
                });
                if (paymentDetails === null || paymentDetails === void 0 ? void 0 : paymentDetails.type) {
                    paymentDetails.type = PAYMENT_GATEWAYS_MAP[paymentDetails.type];
                }
                const isVoucher = magentoOrder.orderType === VOUCHER_ORDER_TYPE;
                const pendingPrescription = fulfillmentOrder
                    ? magentoOrder.bundleItems.filter((item) => { var _a; return ((_a = fulfillmentOrder.packages[0].items.find((fulfillmentItem) => fulfillmentItem.id === item.id)) === null || _a === void 0 ? void 0 : _a.status) !== 'cancelled'; }).some((item) => item.prescription.pending_prescription)
                    : magentoOrder.bundleItems.some((item) => item.prescription.pending_prescription);
                // workaround to know if the order is a goose order
                // 2024-08-13: the order won't have mixed items, so the partner id of the first item will be the partner id of the order
                const isGooseOrder = (_b = magentoOrder === null || magentoOrder === void 0 ? void 0 : magentoOrder.partnerId) === null || _b === void 0 ? void 0 : _b.includes('goose');
                state.order = {
                    ...state.order,
                    email: magentoOrder.customerEmail,
                    billingAddress: paymentDetails ? paymentDetails.billing_address : magentoOrder.billingAddress,
                    shippingAddress: magentoOrder.displayShippingAddress || magentoOrder.shippingAddress,
                    // New Voucher orders might have Pending as state and we should treat them as processing
                    status: isVoucher && magentoOrder.status.toLowerCase() === 'pending' ? 'Processing' : magentoOrder.status,
                    subTotal: magentoOrder.bundleItems.reduce((acc, item) => acc + item.price * item.quantity, 0),
                    subTotalWithTax: magentoOrder.bundleItems.reduce((acc, item) => acc + item.priceWithTax, 0),
                    tax: (magentoOrder.tax || 0),
                    shipping: magentoOrder.shippingPrice - (magentoOrder.shippingDiscount || 0),
                    shippingTax: magentoOrder.shippingTax,
                    // workaround to define the carrier_title to know if it should use goose or crawl estimate delivery time
                    shippingType: `${isGooseOrder ? 'goose_' : ''}${((_c = fulfillmentOrder === null || fulfillmentOrder === void 0 ? void 0 : fulfillmentOrder.packages[0]) === null || _c === void 0 ? void 0 : _c.shipping_type) || magentoOrder.shippingType}`,
                    firstName: magentoOrder.customerFirstName,
                    lastName: magentoOrder.customerLastName,
                    phoneNumber: magentoOrder.customerPhoneNumber,
                    globalCurrencyCode: magentoOrder.globalCurrencyCode,
                    currencySymbol: magentoOrder.currencySymbol,
                    storeCode: magentoOrder.storeCode,
                    paymentDetails,
                    orderType: magentoOrder.orderType,
                    isVoucher,
                    isCancelled: cancelledStatuses.has(((magentoOrder === null || magentoOrder === void 0 ? void 0 : magentoOrder.status) || '').toLowerCase()),
                    products,
                    globalOrderId,
                    total: magentoOrder.grandTotal,
                    totalItems: magentoOrder.bundleItems.length,
                    pendingPrescription,
                    // we might have an order with only one non-prescription item and hsa/fsa receipt will fail
                    atLeastOnePrescription: magentoOrder.bundleItems.some((item) => item.prescription.is_prescription),
                    trackingNumber: (_d = fulfillmentOrder === null || fulfillmentOrder === void 0 ? void 0 : fulfillmentOrder.packages[0]) === null || _d === void 0 ? void 0 : _d.tracking_number,
                    trackingLink: (_e = fulfillmentOrder === null || fulfillmentOrder === void 0 ? void 0 : fulfillmentOrder.packages[0]) === null || _e === void 0 ? void 0 : _e.tracking_link,
                    carrier: (_f = fulfillmentOrder === null || fulfillmentOrder === void 0 ? void 0 : fulfillmentOrder.packages[0]) === null || _f === void 0 ? void 0 : _f.carrier,
                    createdAt: magentoOrder.createdAt.split(' ')[0],
                    isGooseOrder,
                };
                return true;
            }
            catch (err) {
                Logger.error('Failed to get order with global order id', err);
                // throw err;
            }
        }
        return false;
    };
    const getCouponCode = async (globalOrderId) => {
        if (globalOrderId) {
            try {
                const url = `/coupon-code?globalOrderId=${globalOrderId}`;
                const couponCodeReq = await fetch(url);
                if (!couponCodeReq.ok)
                    return;
                const { couponCode } = await couponCodeReq.json();
                state.couponCode = couponCode;
                state.order = {
                    ...state.order,
                    couponCode,
                };
            }
            catch (err) {
                Logger.error('Failed to get coupon', err);
            }
        }
    };
    const verifyEmail = async (emailAddress, globalOrderId) => {
        const url = '/verify-email';
        const reqBody = {
            emailAddress,
            globalOrderId,
        };
        const verifyEmailReq = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(reqBody),
        });
        const { verified } = await verifyEmailReq.json();
        return verified;
    };
    const verifyVoucher = async (emailAddress, globalOrderId) => {
        const url = '/verify-voucher';
        const reqBody = {
            emailAddress,
            globalOrderId,
        };
        const verifyEmailReq = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(reqBody),
        });
        const { verified } = await verifyEmailReq.json();
        return verified;
    };
    const setOrder = (order) => {
        state.order = order;
    };
    const cancelVoucherItem = async (itemId) => {
        if (!itemId)
            return false;
        const url = `/cancel-voucher-item?itemId=${itemId}`;
        try {
            const cancelVoucherReq = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return cancelVoucherReq.ok;
        }
        catch (err) {
            console.log('Failed to cancel voucher', err);
            return false;
        }
    };
    const order = computed(() => state.order);
    const couponCode = computed(() => state.couponCode);
    const isPreOrder = computed(() => {
        var _a, _b;
        const currentDate = new Date();
        const preOrderEndDateStr = ((_a = state.order) === null || _a === void 0 ? void 0 : _a.isGooseOrder) ? PRE_ORDER_DATES[QUEST_GOOSE_BUNDLE_SKU] : PRE_ORDER_DATES[QUEST_3_BUNDLE_SKU];
        const preOrderEndDate = new Date(preOrderEndDateStr(((_b = state.order) === null || _b === void 0 ? void 0 : _b.storeCode) || 'us'));
        return currentDate < preOrderEndDate;
    });
    const sendGTMEvent = ({ event, withItem, params = {} }) => {
        var _a, _b, _c;
        $gtm.push({ ecommerce: null });
        const items = withItem
            ? (Array.isArray(withItem)
                ? withItem.map((itemId) => mapProductItemToGTMProduct(itemId, state.order.products))
                : [mapProductItemToGTMProduct(withItem, state.order.products)])
            : undefined;
        // we are using the first item partner id as we defined in the frontend that an order cannot be mixed with different partners
        $gtm.push({
            event,
            customer_id: userId,
            partner_id: ((_c = (_b = (_a = order.value) === null || _a === void 0 ? void 0 : _a.products) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.partnerId) || (order.value.isVoucher ? VOUCHER_FLOW_PARTNER_ID : VR_FLOW_PARTNER_ID),
            beast_flow_option: order.value.isVoucher ? 'voucher-flow' : 'vr-flow',
            ecommerce: {
                transaction_id: state.order.globalOrderId,
                items,
            },
            ...params,
        });
    };
    return {
        order,
        couponCode,
        getOrder,
        setOrder,
        getCouponCode,
        cancelledStatuses,
        verifyEmail,
        verifyVoucher,
        cancelVoucherItem,
        isPreOrder,
        getCustomizableOptionsFromOrder,
        isItemPendingPrescription,
        sendGTMEvent,
        gtmPrescriptionMethodChosen,
    };
};
