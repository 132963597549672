import { integrationPlugin } from '~/modules/core/integrationPlugin';
import { mapConfigToSetupObject } from '~/modules/core/helpers/mapConfigToSetupObject';
import { defaultConfig } from '~/modules/core/defaultConfig';
const moduleOptions = JSON.parse('{"cookies":{"currencyCookieName":"vsf-currency","countryCookieName":"vsf-country","localeCookieName":"vsf-locale","cartCookieName":"vsf-cart","customerCookieName":"vsf-customer","storeCookieName":"vsf-store","messageCookieName":"vsf-message"},"cookiesDefaultOpts":{"httpOnly":false,"secure":false,"sameSite":"lax","path":"/"},"externalCheckout":{"enable":false,"cmsUrl":"https://{YOUR_SITE_FRONT_URL}","syncUrlPath":"/vue/cart/sync","stores":{"us":false}},"defaultStore":"us","magentoBaseUrl":"https://beast-magento.zenni.io","imageProvider":"ipx","magentoApiEndpoint":"https://beast-magento.zenni.io/graphql","customApolloHttpLinkOptions":{"useGETForQueries":true},"customer":{"customer_create_account_confirm":true}}');
export default integrationPlugin((plugin) => {
    const getCookieName = (property) => { var _a, _b; return (_b = (_a = moduleOptions.cookies) === null || _a === void 0 ? void 0 : _a[property]) !== null && _b !== void 0 ? _b : defaultConfig.cookies[property]; };
    const cookieOpts = {
        [defaultConfig.cookies.currencyCookieName]: { ...moduleOptions.cookiesDefaultOpts },
        [defaultConfig.cookies.localeCookieName]: { ...moduleOptions.cookiesDefaultOpts },
        [defaultConfig.cookies.storeCookieName]: { ...moduleOptions.cookiesDefaultOpts },
        [defaultConfig.cookies.customerCookieName]: { ...moduleOptions.cookiesDefaultOpts },
        [defaultConfig.cookies.cartCookieName]: { ...moduleOptions.cookiesDefaultOpts },
        [defaultConfig.cookies.messageCookieName]: { ...moduleOptions.cookiesDefaultOpts },
    };
    const getCookieOpt = (cookieName) => { var _a; return (_a = cookieOpts[cookieName]) !== null && _a !== void 0 ? _a : {}; };
    const cookieNames = {
        cart: getCookieName('cartCookieName'),
        customer: getCookieName('customerCookieName'),
        currency: getCookieName('currencyCookieName'),
        store: getCookieName('storeCookieName'),
        locale: getCookieName('localeCookieName'),
        country: getCookieName('countryCookieName'),
        message: getCookieName('messageCookieName'),
    };
    const { $cookies } = plugin.app;
    const createCookieOperationsInstance = (cookies) => (cookieName) => ({
        get: (opts) => cookies.get(cookieName, opts),
        set: (value, opts) => cookies.set(cookieName, value, { ...getCookieOpt(cookieName), ...opts }),
        remove: (opts) => cookies.remove(cookieName, opts),
    });
    const createCookieOperations = createCookieOperationsInstance($cookies);
    // TODO Refactor to separate containers (state.cart.get() .set() .remove()) - this requires a breaking change in api-client types
    const { get: getCartId, set: setCartId, remove: removeCartId, } = createCookieOperations(cookieNames.cart);
    const { get: getCustomerToken, set: setCustomerToken, remove: removeCustomerToken, } = createCookieOperations(cookieNames.customer);
    const { get: getStore, set: setStore, remove: removeStore, } = createCookieOperations(cookieNames.store);
    const { get: getCurrency, set: setCurrency, remove: removeCurrency, } = createCookieOperations(cookieNames.currency);
    const { get: getLocale, set: setLocale, remove: removeLocale, } = createCookieOperations(cookieNames.locale);
    const { get: getCountry, set: setCountry, remove: removeCountry, } = createCookieOperations(cookieNames.country);
    const { get: getMessage, set: setMessage, remove: removeMessage, } = createCookieOperations(cookieNames.message);
    const settings = mapConfigToSetupObject({
        moduleOptions,
        app: plugin.app,
        additionalProperties: {
            state: {
                getCartId,
                setCartId,
                removeCartId,
                getCustomerToken,
                setCustomerToken,
                removeCustomerToken,
                getStore,
                setStore,
                removeStore,
                getCurrency,
                setCurrency,
                removeCurrency,
                getLocale,
                setLocale,
                removeLocale,
                getCountry,
                setCountry,
                removeCountry,
                getMessage,
                setMessage,
                removeMessage,
            },
        },
    });
    plugin.integration.configure('magento', settings);
});
