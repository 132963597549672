import { defineComponent, ref, onMounted, nextTick, onBeforeUnmount, watch, } from '@nuxtjs/composition-api';
import Glide from '@glidejs/glide/dist/glide';
export default defineComponent({
    name: 'ZnCarousel',
    components: { InstallVideo: () => import('~/modules/b2b/components/beast/InstallVideo.vue') },
    props: {
        images: {
            type: Array,
            default: () => [],
        },
        description: {
            type: String,
            required: false,
            default: '',
        },
    },
    setup(props) {
        const glide = ref(null);
        const glideInstance = ref(null);
        const activeIndex = ref(0);
        onMounted(() => {
            nextTick(() => {
                if (glide.value) {
                    glideInstance.value = new Glide(glide.value, {
                        type: 'carousel',
                        perView: 1,
                        gap: 15,
                    });
                    glideInstance.value.mount();
                    glideInstance.value.on('run', () => {
                        activeIndex.value = glideInstance.value.index;
                    });
                }
            });
        });
        const startVideo = () => {
            const videoPlayer = document.querySelector('#carouselVideoPlayer');
            if (videoPlayer) {
                videoPlayer.play();
            }
        };
        const pauseVideo = () => {
            const videoPlayer = document.querySelector('#carouselVideoPlayer');
            if (videoPlayer) {
                videoPlayer.pause();
            }
        };
        watch(activeIndex, (index) => {
            if (!Number.isNaN(index)) {
                if (props.images[index].alt.toLowerCase().includes('video')) {
                    startVideo();
                }
                else {
                    pauseVideo();
                }
            }
        });
        const go = (index) => {
            glideInstance.value.go(`=${index}`);
            activeIndex.value = index;
        };
        onBeforeUnmount(() => {
            glideInstance.value.destroy();
        });
        return {
            glide,
            activeIndex,
            go,
        };
    },
});
